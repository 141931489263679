import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { AclService, Client, ClientService, User } from '../entities';
import { AppStateService } from '../shared/app-state.service';
import { Dictionary } from '../shared/data.structures';
import { Lookup } from '../shared/lookup';
import { ToastService } from '../shared/toast/toast.service';
import {
  ApolloSearchResults,
  ResultSet,
  SearchComponentTypes,
  SearchResult,
  SearchService
} from './search.service';

@Component({
  selector: 'global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('flipOpenClose', [
      state(
        'open',
        style({
          opacity: 1,
          pointerEvents: 'auto'
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          pointerEvents: 'none'
        })
      ),
      transition('closed => open', [
        animate(
          300,
          keyframes([
            style({
              opacity: 0,
              height: '10%',
              transform: 'translate3d(-90%, 0, 0)',
              offset: 0
            }),
            style({
              opacity: 0.5,
              height: '10%',
              transform: 'translate3d(0, 0, 0)',
              offset: 0.5
            }),
            style({
              opacity: 1,
              height: '100%',
              transform: 'translate3d(0, 0, 0)',
              offset: 1.0
            })
          ])
        )
      ]),
      transition('open => closed', [
        animate(
          300,
          keyframes([
            style({
              opacity: 1,
              height: '100%',
              transform: 'translate3d(0, 0, 0)',
              offset: 0
            }),
            style({
              opacity: 0.5,
              height: '10%',
              transform: 'translate3d(0, 0, 0)',
              offset: 0.5
            }),
            style({
              opacity: 0,
              height: '10%',
              transform: 'translate3d(-90%, 0, 0)',
              offset: 1.0
            })
          ])
        )
      ])
    ]),
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition('void => *', [style({ opacity: 1 }), animate(200)]),
      transition('* => void', [animate(200, style({ opacity: 0 }))])
    ])
  ]
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('clientTabs') clientTabs: ElementRef;
  state = 'closed';
  inputControl: UntypedFormControl;
  clients: Dictionary<Client>;
  searchResults: ResultSet[];
  selectedResultSet: ResultSet;
  showInactive: boolean;
  searchInProgress: boolean;
  disableLeftScroll: boolean;
  disableRightScroll: boolean;
  inSelectionMode: boolean;
  topTitle: string;
  setForSelectedItems: ResultSet;
  subs: Subscription[];
  user = new User();
  hasLinkGeneratorAccess = false;
  hasUserManagementAccess = false;
  hasSettingsAccess = false;
  hasManagementMapsAccess = false;
  inProgressSearchSubscription: Subscription;

  constructor(
    private searchService: SearchService,
    private auth: AuthService,
    private clientService: ClientService,
    private router: Router,
    private appState: AppStateService,
    private toaster: ToastService,
    private aclService: AclService
  ) {
    this.inputControl = new UntypedFormControl();
    this.clients = new Dictionary<Client>();
    this.searchResults = null;
    this.showInactive = false;
    this.searchInProgress = false;
    this.disableLeftScroll = false;
    this.disableRightScroll = false;
    this.inSelectionMode = false;
    this.subs = new Array<Subscription>();
    this.setForSelectedItems = new ResultSet(0, 'Selected Item(s)', new ApolloSearchResults());
  }

  ngOnInit() {
    this.user = this.auth.currentUser;
    this.checkUserAccess();

    // subscribe on search input value
    this.subs.push(
      this.inputControl.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((term: string) => {
          this.processSearch(term);
        })
    );

    // listen for changes from service regarding component state
    // this is how any part of the app can request to open/close the GSC
    this.subs.push(
      this.searchService.componentState
        .pipe(filter(cState => cState.targetedComponent === SearchComponentTypes.Global))
        .subscribe(cState => {
          this.inSelectionMode = cState.inSelectionMode;
          this.topTitle = cState.topTitle;

          if (cState.isOpen) this.open();
          else this.close();
        })
    );

    if (this.auth.isSuperAdmin()) {
      // if ml-admin then we will need all clients
      this.clientService.getAll().subscribe(
        clients => {
          clients.forEach(c => this.clients.addOrUpdate(`${c.ClientId}`, c));
        },
        error => {
          this.toaster.showError(
            'Error',
            'An error occurred while trying to retrieve the client list. Please try again or contact support.'
          );
        }
      );
    } else {
      // otherwise only need this user's client
      this.clientService.getById(this.auth.currentUser.ClientId).subscribe(client => {
        this.clients.addOrUpdate(`${client.ClientId}`, client);
      });
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  processSearch(term: string) {
    // only hit API if term is at least 3 chars
    if (term && term.trim() && term.length > 2) {
      const currentSet = this.selectedResultSet;
      this.selectedResultSet = null;
      this.searchInProgress = true;

      if (this.inProgressSearchSubscription && !this.inProgressSearchSubscription.closed) {
        this.inProgressSearchSubscription.unsubscribe();
        this.inProgressSearchSubscription = null;
      }

      this.inProgressSearchSubscription = this.searchService
        .go(term, this.showInactive)
        .subscribe(results => {
          this.searchResults = new Array<ResultSet>();

          // format results into an array of ResultSets (1 set per client)
          results.keys().forEach(k => {
            if (this.clients.containsKey(k)) {
              const set: ApolloSearchResults = results[k];

              // re-check any selected items that are still in matching results
              if (this.inSelectionMode) this.checkSetAndRefreshSelectedItems(set);

              this.searchResults.push(
                new ResultSet(this.clients[k].ClientId, this.clients[k].Name, set)
              );
            }
          });

          // sort sets by client name
          this.searchResults.sort((a: ResultSet, b: ResultSet) => {
            if (a.ClientName < b.ClientName) return -1;
            if (a.ClientName > b.ClientName) return 1;
            return 0;
          });

          // Filters out any results the user isn't mapped to
          if (!this.user.isSuperAdmin) {
            this.searchResults.forEach(x => {
              if (this.user.isDivisionAdmin) {
                x.Results.Communities = x.Results.Communities.filter(comm =>
                  comm.Lineage.some(l => this.user.mappedDivisionIds.some(id => id === +l.Id))
                );

                x.Results.Neighborhoods = x.Results.Neighborhoods.filter(neighborhood =>
                  neighborhood.Lineage.some(l =>
                    this.user.mappedDivisionIds.some(id => id === +l.Id)
                  )
                );

                x.Results.FloorPlans = x.Results.FloorPlans.filter(floorplan =>
                  floorplan.Lineage.some(fpLineage =>
                    this.user.mappedDivisionIds.some(id => id === +fpLineage.Id)
                  )
                );
              }
              if (this.user.isStandardUser) {
                x.Results.Communities = x.Results.Communities.filter(comm =>
                  this.user.mappedCommunityIds.some(id => id === +comm.Id)
                );

                x.Results.Neighborhoods = x.Results.Neighborhoods.filter(neighborhood =>
                  neighborhood.Lineage.some(l =>
                    this.user.mappedCommunityIds.some(id => id === +l.Id)
                  )
                );

                x.Results.FloorPlans = x.Results.FloorPlans.filter(floorplan =>
                  floorplan.Lineage.some(fpLineage =>
                    this.user.mappedCommunityIds.some(id => id === +fpLineage.Id)
                  )
                );
              }
            });
          }

          // after searching if the client set is still in results then re-select it
          let freshCurrent = null;
          if (currentSet)
            freshCurrent = this.searchResults.find(s => s.ClientId === currentSet.ClientId);

          if (freshCurrent) {
            this.selectedResultSet = freshCurrent;
          } else {
            this.selectedResultSet = this.searchResults[0];
          }
          this.searchInProgress = false;

          // if in selection mode then add in that set
          if (this.inSelectionMode) {
            this.searchResults.unshift(this.setForSelectedItems);
            if (!this.selectedResultSet) this.selectedResultSet = this.setForSelectedItems;
          }

          setTimeout(() => {
            this.checkScroll();
          }, 0);
        });
    } else {
      if (this.inSelectionMode) {
        this.selectedResultSet = this.setForSelectedItems;
        this.searchResults = [this.setForSelectedItems];
      } else {
        this.selectedResultSet = null;
        this.searchResults = null;
      }
    }
  }

  private async checkUserAccess() {
    if (this.auth.isSuperAdmin()) {
      this.hasLinkGeneratorAccess = true;
      this.hasUserManagementAccess = true;
      this.hasSettingsAccess = true;
      this.hasManagementMapsAccess = true;
    } else {
      const currentUserAcls = await this.aclService
        .getModulesAuthorizedForUser(this.user)
        .toPromise();

      if (currentUserAcls.some(acl => acl.ResourceName === Lookup.Modules.UserManagement.Name))
        this.hasUserManagementAccess = true;

      if (currentUserAcls.some(acl => acl.ResourceName === Lookup.Modules.Admin.Name)) {
        this.hasSettingsAccess = true;
      }

      if (currentUserAcls.some(acl => acl.ResourceName === Lookup.Modules.ManagementMaps.Name))
        this.hasManagementMapsAccess = true;

      const toolsMod = Lookup.Modules.Tools;
      const toolsAcl = currentUserAcls.find(x => x.ResourceName === toolsMod.Name);
      if (toolsAcl && toolsMod.SpecialOperations.LinkGenerator.isUserAllowed(this.user, toolsAcl)) {
        this.hasLinkGeneratorAccess = true;
      }
    }
  }

  selectSet(set: ResultSet) {
    this.selectedResultSet = set;
  }

  checkScroll() {
    if (this.clientTabs) {
      this.disableLeftScroll = this.clientTabs.nativeElement.scrollLeft === 0;
      this.disableRightScroll =
        this.clientTabs.nativeElement.scrollLeft ===
        this.clientTabs.nativeElement.scrollWidth - this.clientTabs.nativeElement.clientWidth;
    }
  }

  scrollTabs(direction: number) {
    this.clientTabs.nativeElement.scrollLeft += direction * 300;
    this.checkScroll();
  }

  toggleShowInactive() {
    this.showInactive = !this.showInactive;

    this.processSearch(this.inputControl.value);
  }

  toggleSelection(result: SearchResult, sType: string) {
    if (!this.inSelectionMode) return;

    const isSelected = !result.isSelected;

    if (isSelected) {
      result.isSelected = true;
      this.setForSelectedItems.Results[sType].push(result);
    } else {
      result.isSelected = false;
      const i = this.setForSelectedItems.Results[sType].findIndex(r => r.Id === result.Id);
      if (i > -1) this.setForSelectedItems.Results[sType].splice(i, 1);
    }
  }

  // new JS objects are created every search so we need to find results that still match and update selected list with new objects
  checkSetAndRefreshSelectedItems(set: ApolloSearchResults) {
    Object.keys(this.setForSelectedItems.Results).forEach(sType => {
      this.setForSelectedItems.Results[sType].forEach((r, i, arr) => {
        const fresh = set.allResults.find(f => f.Id === r.Id);
        if (fresh) {
          fresh.isSelected = true;
          this.setForSelectedItems.Results[sType][i] = fresh;
        }
      });
    });
  }

  navigate(
    route: string,
    result: SearchResult = null,
    clientId: number = 0,
    divisionId: number = 0,
    communityId: number = 0,
    neighborhoodId: number = 0
  ) {
    if (this.inSelectionMode) return;

    this.close();

    if (!route.startsWith('/')) route = '/' + route;

    // set app state values if given
    if (clientId) this.appState.clientId = +clientId;
    if (divisionId) this.appState.divisionId = +divisionId;
    if (communityId) this.appState.communityId = +communityId;
    if (neighborhoodId) this.appState.neighborhoodId = +neighborhoodId;

    this.router.navigateByUrl(
      route.toLowerCase() + `;fromSearch=true;resultId=${result ? result.Id : 0}`
    );
  }

  open() {
    this.state = 'open';
    this.searchInput.nativeElement.focus();

    // when opening in selection mode and search results already loaded then make sure setForSelectedItems is added
    if (this.inSelectionMode) {
      if (this.searchResults) {
        this.searchResults.forEach(s => this.checkSetAndRefreshSelectedItems(s.Results));
        this.searchResults.unshift(this.setForSelectedItems);
      } else {
        this.selectedResultSet = this.setForSelectedItems;
        this.searchResults = [this.setForSelectedItems];
      }
    }
  }

  close() {
    this.state = 'closed';
  }

  @HostListener('window:keydown', ['$event'])
  onHotKey(evt: KeyboardEvent) {
    // Ctrl + Shift + F
    if (evt.ctrlKey && evt.shiftKey && evt.keyCode === 70) {
      // opening from hotkey will not use selection and if in selection previously then clear out
      if (this.inSelectionMode) {
        this.inSelectionMode = false;
        this.topTitle = '';
        // remove setForSelectedItems from searchResults
        this.searchResults.shift();
        this.selectedResultSet = null;

        if (this.searchResults && this.searchResults.length > 0)
          this.selectedResultSet = this.searchResults[0];
      }

      this.open();
    } else if (evt.keyCode === 27) {
      this.close();
    }
  }
}
