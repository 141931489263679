<div id="fpSearchContainer" class="overlay" [class.selection-mode]="inSelectionMode" [@flipOpenClose]="state">
    <div class="content">
        <div *ngIf="topTitle" class="top-title">{{topTitle}}</div>
        <button *ngIf="inSelectionMode" class="btn standard-positive submit" [class.disabled]="!setForSelectedItems.Results.FloorPlans.length"
                (click)="addFpSelectionsToOrder()">
            <ml-icon name="plus"></ml-icon>
            Add to order
        </button>
        <div class="header">
            <div id="fpSearchBox">
                <div class="toggle-container btn" (click)="toggleShowInactive()">
                    <div class="toggle" [class.active]="showInactive">
                        <div class="dot"></div>
                    </div>
                    <div class="label">Show Inactive</div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.87" height="19.02" viewBox="0 0 18.87 19.02">
                    <title>magnifying-glass</title>
                    <g id="5e3f6ecf-e342-49e4-a0c2-c82242936265" data-name="Layer 2">
                        <g id="03bb6e0f-f5a7-415a-ae12-0250a9f5ff60" data-name="Layer 1">
                            <path d="M2.25,7.71a5.45,5.45,0,1,1,5.45,5.45A5.45,5.45,0,0,1,2.25,7.71Zm16.61,9.45L14,12.25a7.71,7.71,0,1,0-1.89,1.84L17,19Z" fill="#292929" />
                        </g>
                    </g>
                </svg>
                <div class="input-wrapper">
                    <input #searchInput name="globalSearch" type="text" autofocus
                           placeholder="Search by Floorplan" [formControl]="inputControl" />
                </div>
            </div>
            <div class="close icon btn" (click)="close()" title="Esc">
                <svg xmlns="http://www.w3.org/2000/svg" width="14.6" height="14.6" viewBox="0 0 14.6 14.6">
                    <title>close</title>
                    <g id="e3e420db-5d6d-4e78-80e1-f0b1b8626a40" data-name="Layer 2">
                        <g id="b84b5d25-7450-4185-9dfd-881c2d046679" data-name="Layer 1">
                            <path d="M8.23,7.3,14.4,1.13a.64.64,0,0,0,0-.93.64.64,0,0,0-.93,0L7.3,6.37,1.13.2A.64.64,0,0,0,.2.2a.64.64,0,0,0,0,.93L6.37,7.3.2,13.47a.64.64,0,0,0,0,.93.6.6,0,0,0,.46.2.6.6,0,0,0,.46-.2L7.3,8.23l6.17,6.17a.64.64,0,0,0,.93,0,.64.64,0,0,0,0-.93Z" fill="#292929" />
                        </g>
                    </g>
                </svg>
            </div>
        </div>
        <div class="body">
            <div #clientTabs class="tab-container" *ngIf="searchResults?.length > 0">
                <div *ngFor="let set of searchResults" class="tab btn" [class.hide]="set.ClientId === 0 && !inSelectionMode" (click)="selectSet(set)"
                     [class.selected]="selectedResultSet && set.ClientName === selectedResultSet.ClientName">
                    {{set.ClientName}}
                </div>
            </div>
            <div class="arrows-container" *ngIf="searchResults?.length > 0">
                <div class="icon btn arrow left" [class.disabled]="disableLeftScroll" (click)="scrollTabs(-1)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 15.44">
                        <title>carrot</title>
                        <g id="b34694ab-ee1d-47f3-94f0-53d9069fb511" data-name="Layer 2">
                            <g id="a10f8616-2a18-4695-9988-5bd3953a77bf" data-name="Layer 1">
                                <path d="M8.62,7,1.62.27A1,1,0,0,0,.28.27a.94.94,0,0,0,0,1.34l6.27,6.1L.28,13.82a.94.94,0,0,0,0,1.34.91.91,0,0,0,.67.28.9.9,0,0,0,.66-.28l7-6.78A.94.94,0,0,0,8.62,7Z" fill="#95989a" />
                            </g>
                        </g>
                    </svg></div>
                <div class="icon btn arrow right" [class.disabled]="disableRightScroll" (click)="scrollTabs(1)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 15.44">
                        <title>carrot</title>
                        <g id="b34694ab-ee1d-47f3-94f0-53d9069fb511" data-name="Layer 2">
                            <g id="a10f8616-2a18-4695-9988-5bd3953a77bf" data-name="Layer 1">
                                <path d="M8.62,7,1.62.27A1,1,0,0,0,.28.27a.94.94,0,0,0,0,1.34l6.27,6.1L.28,13.82a.94.94,0,0,0,0,1.34.91.91,0,0,0,.67.28.9.9,0,0,0,.66-.28l7-6.78A.94.94,0,0,0,8.62,7Z" fill="#95989a" />
                            </g>
                        </g>
                    </svg></div>
            </div>
            <hr *ngIf="searchResults?.length > 0" />
            <div class="results-container" *ngIf="selectedResultSet">
                <div class="section" *ngIf="selectedResultSet.Results.FloorPlans?.length > 0">
                    <div class="title">
                        <div class="icon">
                            <svg-helper svgName="ifp"></svg-helper>
                        </div>
                        <div class="label">Floor Plans</div>
                        <div class="count">({{selectedResultSet.Results.FloorPlans.length}})</div>
                    </div>
                    <div class="results">
                        <div class="item btn" *ngFor="let result of selectedResultSet.Results.FloorPlans" [class.inactive]="!result.IsActive" [@fadeInOut]="'in'"
                             (click)="toggleSelection(result, 'FloorPlans')">
                            <checkbox [isSelected]="result.isSelected"></checkbox>
                            <div *ngFor="let parent of result.Lineage | reverse" class="label" [innerHTML]="parent.Name"></div>
                            <div class="label highlight">{{result.Name}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="friendly-message" *ngIf="inputControl.value && inputControl.value.length > 2 && !searchInProgress && searchResults?.length === 0">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="46.6" height="46.6" viewBox="0 0 46.6 46.6">
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path d="M23.2,42.7A19.4,19.4,0,1,0,3.9,23.2,19.5,19.5,0,0,0,23.2,42.7Zm.1,3.9A23.3,23.3,0,1,1,46.6,23.4,23.3,23.3,0,0,1,23.3,46.6Z" fill="#919193" />
                                <path d="M29.6,30.8c-.1-.8-.8-1.3-1.6-1.6a14.1,14.1,0,0,0-9.4-.1,3.3,3.3,0,0,0-1.4,1.1,1.3,1.3,0,0,0-.1,1.2c.1.2.7.3,1,.2a29.9,29.9,0,0,1,4.3-.6,59.4,59.4,0,0,1,6.1.6C29.2,31.8,29.6,31.7,29.6,30.8Zm-.1,4.1a48.5,48.5,0,0,0-5.1-.6,43.8,43.8,0,0,0-6,.4c-2,.3-3.5-.2-4.2-1.6a4.2,4.2,0,0,1,.6-5,8.7,8.7,0,0,1,4.9-2.7,15.2,15.2,0,0,1,9.8.9c2,1,3.6,2.4,3.5,4.9A3.5,3.5,0,0,1,29.5,34.9Z" fill="#919193" />
                                <path d="M19.4,18.5a2.9,2.9,0,1,1-2.8-2.9A2.9,2.9,0,0,1,19.4,18.5Z" fill="#919193" />
                                <path d="M33,18.4a2.9,2.9,0,1,1-2.9-2.9A3,3,0,0,1,33,18.4Z" fill="#919193" />
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="text">
                    Sorry, no results were found with that search.
                </div>
            </div>
            <div class="friendly-message" *ngIf="inputControl.value && inputControl.value.length < 3 && !searchInProgress">
                <div class="text">Search term must be at least 3 characters</div>
            </div>
            <div class="loading-bars" *ngIf="searchInProgress">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>