import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { ApolloCommonModule } from '../shared/apollo.common.module';
import { FpSearchComponent } from './fp-search/fp-search.component';
import { GlobalSearchComponent } from './global-search.component';

@NgModule({
  imports: [RouterModule, ReactiveFormsModule, CommonModule, ApolloCommonModule, MatTooltipModule],
  declarations: [GlobalSearchComponent, FpSearchComponent],
  exports: [GlobalSearchComponent, FpSearchComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchModule {}
