import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { Client, ClientService, User } from '../../entities';
import { AppStateService } from '../../shared/app-state.service';
import { Dictionary } from '../../shared/data.structures';
import {
  ApolloSearchResults,
  ResultSet,
  SearchComponentTypes,
  SearchResult,
  SearchService
} from '../search.service';

@Component({
  selector: 'fp-search',
  templateUrl: './fp-search.component.html',
  styleUrls: ['./fp-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('flipOpenClose', [
      state(
        'open',
        style({
          opacity: 1,
          pointerEvents: 'auto'
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          pointerEvents: 'none'
        })
      ),
      transition('closed => open', [
        animate(
          300,
          keyframes([
            style({
              opacity: 0,
              height: '10%',
              transform: 'translate3d(-90%, 0, 0)',
              offset: 0
            }),
            style({
              opacity: 0.5,
              height: '10%',
              transform: 'translate3d(0, 0, 0)',
              offset: 0.5
            }),
            style({
              opacity: 1,
              height: '100%',
              transform: 'translate3d(0, 0, 0)',
              offset: 1.0
            })
          ])
        )
      ]),
      transition('open => closed', [
        animate(
          300,
          keyframes([
            style({
              opacity: 1,
              height: '100%',
              transform: 'translate3d(0, 0, 0)',
              offset: 0
            }),
            style({
              opacity: 0.5,
              height: '10%',
              transform: 'translate3d(0, 0, 0)',
              offset: 0.5
            }),
            style({
              opacity: 0,
              height: '10%',
              transform: 'translate3d(-90%, 0, 0)',
              offset: 1.0
            })
          ])
        )
      ])
    ]),
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition('void => *', [style({ opacity: 1 }), animate(200)]),
      transition('* => void', [animate(200, style({ opacity: 0 }))])
    ])
  ]
})
export class FpSearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('clientTabs') clientTabs: ElementRef;
  inputControl: UntypedFormControl = new UntypedFormControl();
  showInactive: boolean;
  state = 'closed';
  user = new User();
  clients = new Dictionary<Client>();
  searchResults = new Array<ResultSet>();
  selectedResultSet: ResultSet;
  searchInProgress: boolean;
  subs: Subscription[] = new Array<Subscription>();
  inSelectionMode = true;
  topTitle = '';
  disableLeftScroll = true;
  disableRightScroll = true;
  setForSelectedItems: ResultSet = new ResultSet(0, 'Selected Item(s)', new ApolloSearchResults());
  inProgressSearchSubscription: Subscription;
  inSingleSelectionMode: boolean;

  constructor(
    private searchService: SearchService,
    private auth: AuthService,
    private clientService: ClientService,
    private appState: AppStateService
  ) {}

  ngOnInit(): void {
    this.user = this.auth.currentUser;

    this.subs.push(
      this.inputControl.valueChanges
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((term: string) => {
          this.processSearch(term);
        })
    );
    this.subs.push(
      this.searchService.componentState
        .pipe(filter(cState => cState.targetedComponent === SearchComponentTypes.Floorplans))
        .subscribe(cState => {
          this.inSelectionMode = cState.inSelectionMode;
          this.topTitle = cState.topTitle;
          this.inSingleSelectionMode = cState.singleSelectionMode;

          if (cState.isOpen) this.open();
          else this.close();
        })
    );

    if (this.auth.isSuperAdmin()) {
      // if ml-admin then check app state for current order
      this.clientService.getById(this.appState.clientId).subscribe(client => {
        this.clients.addOrUpdate(`${client.ClientId}`, client);
      });
    } else {
      // otherwise only need this user's client
      this.clientService.getById(this.auth.currentUser.ClientId).subscribe(client => {
        this.clients.addOrUpdate(`${client.ClientId}`, client);
      });
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  checkScroll() {
    if (this.clientTabs) {
      this.disableLeftScroll = this.clientTabs.nativeElement.scrollLeft === 0;
      this.disableRightScroll =
        this.clientTabs.nativeElement.scrollLeft ===
        this.clientTabs.nativeElement.scrollWidth - this.clientTabs.nativeElement.clientWidth;
    }
  }

  scrollTabs(direction: number) {
    this.clientTabs.nativeElement.scrollLeft += direction * 300;
    this.checkScroll();
  }

  toggleShowInactive() {
    this.showInactive = !this.showInactive;
  }

  toggleSelection(result: SearchResult, sType: string) {
    const isSelected = !result.isSelected;

    if (isSelected) {
      result.isSelected = true;
      this.setForSelectedItems.Results[sType].push(result);
    } else {
      result.isSelected = false;
      const i = this.setForSelectedItems.Results[sType].findIndex(r => r.Id === result.Id);
      if (i > -1) this.setForSelectedItems.Results[sType].splice(i, 1);
    }

    if (this.inSingleSelectionMode) {
      this.addFpSelectionsToOrder();
    }
  }

  selectSet(set: ResultSet) {
    this.selectedResultSet = set;
  }

  open() {
    this.state = 'open';
    this.searchInput.nativeElement.focus();

    if (this.searchResults?.length) {
      this.searchResults.forEach(s => this.checkSetAndRefreshSelectedItems(s.Results));
      if (!this.searchResults.includes(this.setForSelectedItems))
        this.searchResults.unshift(this.setForSelectedItems);
    } else {
      this.selectedResultSet = this.setForSelectedItems;
      this.searchResults = [this.setForSelectedItems];
    }
  }

  close() {
    this.state = 'closed';
  }

  processSearch(term: string) {
    // only hit API if term is at least 2 chars
    if (term && term.trim() && term.length > 2) {
      const currentSet = this.selectedResultSet;
      this.selectedResultSet = null;
      this.searchInProgress = true;
      if (this.inProgressSearchSubscription && !this.inProgressSearchSubscription.closed) {
        this.inProgressSearchSubscription.unsubscribe();
        this.inProgressSearchSubscription = null;
      }

      this.inProgressSearchSubscription = this.searchService
        .go(term, this.showInactive, true)
        .subscribe(results => {
          this.searchResults = new Array<ResultSet>();
          // format results into an array of ResultSets (1 set per client)
          results.keys().forEach(k => {
            if (this.clients.containsKey(k)) {
              const set: ApolloSearchResults = results[k];
              // re-check any selected items that are still in matching results
              this.checkSetAndRefreshSelectedItems(set);
              this.searchResults.push(
                new ResultSet(this.clients[k].ClientId, this.clients[k].Name, set)
              );
            }
          });
          // sort sets by client name
          this.searchResults.sort((a: ResultSet, b: ResultSet) => {
            if (a.ClientName < b.ClientName) return -1;
            if (a.ClientName > b.ClientName) return 1;
            return 0;
          });
          // Filters out any results the user isn't mapped to
          if (!this.user.isSuperAdmin) {
            this.searchResults.forEach(x => {
              if (this.user.isDivisionAdmin) {
                x.Results.FloorPlans = x.Results.FloorPlans.filter(floorplan =>
                  floorplan.Lineage.some(fpLineage =>
                    this.user.mappedDivisionIds.some(id => id === +fpLineage.Id)
                  )
                );
              }
              if (this.user.isStandardUser) {
                x.Results.FloorPlans = x.Results.FloorPlans.filter(floorplan =>
                  floorplan.Lineage.some(fpLineage =>
                    this.user.mappedCommunityIds.some(id => id === +fpLineage.Id)
                  )
                );
              }
            });
          }
          // after searching if the client set is still in results then re-select it
          let freshCurrent = null;
          if (currentSet)
            freshCurrent = this.searchResults.find(s => s.ClientId === currentSet.ClientId);
          if (freshCurrent) {
            this.selectedResultSet = freshCurrent;
          } else {
            this.selectedResultSet = this.searchResults[0];
          }
          this.searchInProgress = false;
          // if in selection mode then add in that set
          if (this.inSelectionMode) {
            this.searchResults.unshift(this.setForSelectedItems);
            if (!this.selectedResultSet) this.selectedResultSet = this.setForSelectedItems;
          }
          setTimeout(() => {
            this.checkScroll();
          }, 0);
        });
    } else {
      if (this.inSelectionMode) {
        this.selectedResultSet = this.setForSelectedItems;
        this.searchResults = [this.setForSelectedItems];
      } else {
        this.selectedResultSet = null;
        this.searchResults = null;
      }
    }
  }

  addFpSelectionsToOrder() {
    const fpsToAdd = this.setForSelectedItems.Results.FloorPlans;
    if (fpsToAdd.length) {
      this.searchService.setComponentState({
        isOpen: false,
        targetedComponent: SearchComponentTypes.Floorplans,
        searchResults: fpsToAdd
      });
      this.resetSearch();
    }
  }

  private checkSetAndRefreshSelectedItems(set: ApolloSearchResults) {
    Object.keys(this.setForSelectedItems.Results).forEach(sType => {
      this.setForSelectedItems.Results[sType].forEach((r, i) => {
        const fresh = set.allResults.find(f => f.Id === r.Id);
        if (fresh) {
          fresh.isSelected = true;
          this.setForSelectedItems.Results[sType][i] = fresh;
        }
      });
    });
  }

  private resetSearch() {
    this.setForSelectedItems = new ResultSet(0, 'Selected Item(s)', new ApolloSearchResults());
    this.selectedResultSet = this.setForSelectedItems;
    this.searchResults = [this.setForSelectedItems];
    this.searchInput.nativeElement.value = '';
    this.close();
  }

  @HostListener('window:keydown', ['$event'])
  onHotKey(evt: KeyboardEvent) {
    if (evt.code === 'Escape' || (evt.shiftKey && evt.ctrlKey && evt.code === 'KeyF')) {
      this.close();
    }
  }
}
