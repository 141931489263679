﻿<div id="globalSearchContainer" class="overlay" [class.selection-mode]="inSelectionMode" [@flipOpenClose]="state">
    <div class="content">
        <div *ngIf="topTitle" class="top-title">{{topTitle}}</div>
        <div *ngIf="inSelectionMode" class="submit btn icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="18.8" height="18.8" viewBox="0 0 18.8 18.8">
                <title>floppy0</title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g id="Group_3772" data-name="Group 3772">
                            <path id="Path_1477" data-name="Path 1477" d="M0,18.8V0H15.3a.8.8,0,0,1,.6.3l2.6,2.7a.8.8,0,0,1,.2.5q0,7.6,0,15.2v.2Zm2.4-1.6H16.4v-7H2.4ZM3.9,7H14.8V1.6H3.9Z" fill="#2f323a" />
                            <path id="Path_1478" data-name="Path 1478" d="M14.1,6.3H11.8V2.4h2.3Z" fill="#2f323a" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div class="header">
            <div id="globalSearchBox">
                <div class="toggle-container btn" (click)="toggleShowInactive()">
                    <div class="toggle" [class.active]="showInactive">
                        <div class="dot"></div>
                    </div>
                    <div class="label">Show Inactive</div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.87" height="19.02" viewBox="0 0 18.87 19.02">
                    <title>magnifying-glass</title>
                    <g id="5e3f6ecf-e342-49e4-a0c2-c82242936265" data-name="Layer 2">
                        <g id="03bb6e0f-f5a7-415a-ae12-0250a9f5ff60" data-name="Layer 1">
                            <path d="M2.25,7.71a5.45,5.45,0,1,1,5.45,5.45A5.45,5.45,0,0,1,2.25,7.71Zm16.61,9.45L14,12.25a7.71,7.71,0,1,0-1.89,1.84L17,19Z" fill="#292929" />
                        </g>
                    </g>
                </svg>
                <div class="input-wrapper">
                    <input #searchInput name="globalSearch" type="text" autofocus
                           placeholder="Search by Community, Neighborhood, etc..." [formControl]="inputControl" />
                </div>
            </div>
            <div class="close icon btn" (click)="close()" title="Esc">
                <svg xmlns="http://www.w3.org/2000/svg" width="14.6" height="14.6" viewBox="0 0 14.6 14.6">
                    <title>close</title>
                    <g id="e3e420db-5d6d-4e78-80e1-f0b1b8626a40" data-name="Layer 2">
                        <g id="b84b5d25-7450-4185-9dfd-881c2d046679" data-name="Layer 1">
                            <path d="M8.23,7.3,14.4,1.13a.64.64,0,0,0,0-.93.64.64,0,0,0-.93,0L7.3,6.37,1.13.2A.64.64,0,0,0,.2.2a.64.64,0,0,0,0,.93L6.37,7.3.2,13.47a.64.64,0,0,0,0,.93.6.6,0,0,0,.46.2.6.6,0,0,0,.46-.2L7.3,8.23l6.17,6.17a.64.64,0,0,0,.93,0,.64.64,0,0,0,0-.93Z" fill="#292929" />
                        </g>
                    </g>
                </svg>
            </div>
        </div>
        <div class="body">
            <div #clientTabs class="tab-container" *ngIf="searchResults?.length > 0">
                <div *ngFor="let set of searchResults" class="tab btn" [class.hide]="set.ClientId === 0 && !inSelectionMode" (click)="selectSet(set)"
                     [class.selected]="selectedResultSet && set.ClientName === selectedResultSet.ClientName">
                    {{set.ClientName}}
                </div>
            </div>
            <div class="arrows-container" *ngIf="searchResults?.length > 0">
                <div class="icon btn arrow left" [class.disabled]="disableLeftScroll" (click)="scrollTabs(-1)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 15.44">
                        <title>carrot</title>
                        <g id="b34694ab-ee1d-47f3-94f0-53d9069fb511" data-name="Layer 2">
                            <g id="a10f8616-2a18-4695-9988-5bd3953a77bf" data-name="Layer 1">
                                <path d="M8.62,7,1.62.27A1,1,0,0,0,.28.27a.94.94,0,0,0,0,1.34l6.27,6.1L.28,13.82a.94.94,0,0,0,0,1.34.91.91,0,0,0,.67.28.9.9,0,0,0,.66-.28l7-6.78A.94.94,0,0,0,8.62,7Z" fill="#95989a" />
                            </g>
                        </g>
                    </svg></div>
                <div class="icon btn arrow right" [class.disabled]="disableRightScroll" (click)="scrollTabs(1)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 15.44">
                        <title>carrot</title>
                        <g id="b34694ab-ee1d-47f3-94f0-53d9069fb511" data-name="Layer 2">
                            <g id="a10f8616-2a18-4695-9988-5bd3953a77bf" data-name="Layer 1">
                                <path d="M8.62,7,1.62.27A1,1,0,0,0,.28.27a.94.94,0,0,0,0,1.34l6.27,6.1L.28,13.82a.94.94,0,0,0,0,1.34.91.91,0,0,0,.67.28.9.9,0,0,0,.66-.28l7-6.78A.94.94,0,0,0,8.62,7Z" fill="#95989a" />
                            </g>
                        </g>
                    </svg></div>
            </div>
            <hr *ngIf="searchResults?.length > 0" />
            <div class="results-container" *ngIf="selectedResultSet">
                <div class="section" *ngIf="selectedResultSet.Results.Communities?.length > 0">
                    <div class="title">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13.93" viewBox="0 0 15 13.93">
                                <g id="03547cd1-c7e9-40a5-af2a-eb8330f1c7c1" data-name="Layer 2">
                                    <g id="bfee942a-e56f-4d1a-976c-af5f7d6f5fcc" data-name="Layer 1">
                                        <path d="M7,3.48Q7,7,3.48,7T0,3.48Q0,0,3.48,0T7,3.48Z" fill="#292929" />
                                        <path d="M15,3.48Q15,7,11.52,7T8,3.48Q8,0,11.52,0T15,3.48Z" fill="#292929" />
                                        <path d="M11.25,10.45q0,3.48-3.48,3.48T4.29,10.45Q4.29,7,7.77,7T11.25,10.45Z" fill="#292929" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="label">Communities</div>
                        <div class="count">({{selectedResultSet.Results.Communities.length}})</div>
                    </div>
                    <div class="results">
                        <div class="item btn" *ngFor="let result of selectedResultSet.Results.Communities" [class.inactive]="!result.IsActive" [@fadeInOut]="'in'"
                             (click)="toggleSelection(result, 'Communities')">
                            <checkbox [isSelected]="result.isSelected"></checkbox>
                            <div *ngFor="let parent of result.Lineage | reverse" class="label" [innerHTML]="parent.Name"></div>
                            <div class="label highlight" [innerHTML]="result.Name"></div>
                            <div class="button-group" *ngIf="result.IsActive">
                                <div id="settings" *ngIf="hasSettingsAccess" class="btn icon" matTooltip="Admin Settings" (click)="navigate('/admin/settings', result, selectedResultSet.ClientId, +result.Lineage[0]?.Id, +result.Id )">
                                    <svg-helper svgName="gear"></svg-helper>
                                </div>
                                <div id="linkGenerator" *ngIf="hasLinkGeneratorAccess" class="btn icon" matTooltip="Link Generator" (click)="navigate('/tools/link-generator', result, selectedResultSet.ClientId, +result.Lineage[0]?.Id, +result.Id )">
                                    <svg-helper svgName="link_generator"></svg-helper>
                                </div>
                                <div id="users" *ngIf="hasUserManagementAccess" class="btn icon" matTooltip="Users" (click)="navigate('/users/list', result, selectedResultSet.ClientId, +result.Lineage[0]?.Id, +result.Id )">
                                    <svg-helper svgName="user_two"></svg-helper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section" *ngIf="selectedResultSet.Results.Neighborhoods?.length > 0">
                    <div class="title">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.35" height="14.7" viewBox="0 0 17.35 14.7">
                                <g id="7c2318c8-6454-4276-b2ea-c2da09ebbaa2" data-name="Layer 2">
                                    <g id="748e1749-1133-4581-b960-2d930a8095e0" data-name="Layer 1">
                                        <path d="M9.18,1,1,9V1ZM7.34,8.11l1.3-1.29,7,6.91H12.85Zm-.69.69,4.83,4.93H1.67ZM9.34,6.13,11.54,4l2.13,2.18L11.54,8.3ZM1,10.33,10.58,1h2.6L1,13Zm15.4,2.72L12.24,9,15,6.13l-2.8-2.87L14.55,1h1.82ZM0,0V14.7H17.35V0Z" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="label">Neighborhoods</div>
                        <div class="count">({{selectedResultSet.Results.Neighborhoods.length}})</div>
                    </div>
                    <div class="results">
                        <div class="item btn" *ngFor="let result of selectedResultSet.Results.Neighborhoods" [class.inactive]="!result.IsActive" [@fadeInOut]="'in'"
                             (click)="toggleSelection(result, 'Neighborhoods')">
                            <checkbox [isSelected]="result.isSelected"></checkbox>
                            <div *ngFor="let parent of result.Lineage | reverse" class="label" [innerHTML]="parent.Name"></div>
                            <div class="label highlight">{{result.Name}}</div>
                            <div class="button-group" *ngIf="result.IsActive">
                                <div id="linkGenerator" *ngIf="hasLinkGeneratorAccess" class="btn icon" matTooltip="Link Generator"
                                     (click)="navigate('/tools/link-generator', result, selectedResultSet.ClientId, +result.Lineage[1]?.Id, +result.Lineage[0]?.Id, +result.Id )">
                                    <svg-helper svgName="link_generator"></svg-helper>
                                </div>
                                <div *ngIf="hasManagementMapsAccess" class="btn icon" matTooltip="Management Maps" (click)="navigate('/management-maps/browse', result, selectedResultSet.ClientId, +result.Lineage[1]?.Id, +result.Lineage[0]?.Id, +result.Id)">
                                    <svg-helper svgName="management_maps_nav"></svg-helper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section" *ngIf="selectedResultSet.Results.Buildings?.length > 0">
                    <div class="title">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="7.49" height="14.06" viewBox="0 0 7.49 14.06">
                                <g id="f5d1aed6-3d70-49b6-b96f-c039f8047c48" data-name="Layer 2">
                                    <g id="4bfeb1fe-80e4-4c39-a689-4815f9dd6eac" data-name="Layer 1">
                                        <polygon points="5.21 0.25 5.21 14.06 7.5 14.06 7.5 2.65 5.21 0.25" fill="#292929" />
                                        <polygon points="0 2.19 0 4.1 3.02 2.65 3.02 4.27 0 5.69 0 6.92 3.02 5.51 3.02 7.1 0 8.55 0 9.64 3.02 8.23 3.02 9.82 0 11.27 0 14.06 4.71 14.06 4.71 0 0 2.19" fill="#292929" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="label">Buildings</div>
                        <div class="count">({{selectedResultSet.Results.Buildings.length}})</div>
                    </div>
                    <div class="results">
                        <div class="item btn" *ngFor="let result of selectedResultSet.Results.Buildings" [class.inactive]="!result.IsActive" [@fadeInOut]="'in'"
                             (click)="toggleSelection(result, 'Buildings')">
                            <checkbox [isSelected]="result.isSelected"></checkbox>
                            <div *ngFor="let parent of result.Lineage | reverse" class="label" [innerHTML]="parent.Name"></div>
                            <div class="label highlight">{{result.Name}}</div>
                            <div class="button-group"></div>
                        </div>
                    </div>
                </div>
                <div class="section" *ngIf="selectedResultSet.Results.FloorPlans?.length > 0">
                    <div class="title">
                        <div class="icon">
                            <svg-helper svgName="ifp"></svg-helper>
                        </div>
                        <div class="label">Floor Plans</div>
                        <div class="count">({{selectedResultSet.Results.FloorPlans.length}})</div>
                    </div>
                    <div class="results">
                        <div class="item btn" *ngFor="let result of selectedResultSet.Results.FloorPlans" [class.inactive]="!result.IsActive" [@fadeInOut]="'in'"
                             (click)="toggleSelection(result, 'FloorPlans')">
                            <checkbox [isSelected]="result.isSelected"></checkbox>
                            <div *ngFor="let parent of result.Lineage | reverse" class="label" [innerHTML]="parent.Name"></div>
                            <div class="label highlight">{{result.Name}}</div>
                            <div class="button-group">
                                <div id="linkGenerator" *ngIf="hasLinkGeneratorAccess" class="btn icon" matTooltip="Link Generator" (click)="navigate('/tools/link-generator', result, selectedResultSet.ClientId, +result.Lineage[2]?.Id, +result.Lineage[1]?.Id, +result.Id )">
                                    <svg-helper svgName="link_generator"></svg-helper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section" *ngIf="selectedResultSet.Results.Units?.length > 0">
                    <div class="title">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="7.95" height="14.26" viewBox="0 0 7.95 14.26">
                                <g id="4b6964d4-c84e-41cd-94c6-8c0b10cd1ed6" data-name="Layer 2">
                                    <g id="f7c14127-3f41-4908-8709-f8ad7db86d43" data-name="Layer 1">
                                        <path d="M3.74,6.19V9.13h.87v-.3H4V6.48h.56V6.19Zm3.63,5V.57H1.44a.24.24,0,0,0,.11.09L5.42,2.34a.27.27,0,0,1,.2.3c0,2.78,0,5.56,0,8.34v.22ZM0,0H7.95V11.78H5.61v2.47l-.91-.41-4.51-2a.28.28,0,0,1-.2-.3Q0,5.85,0,.19C0,.13,0,.07,0,0Z" fill="#292929" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="label">Units</div>
                        <div class="count">({{selectedResultSet.Results.Units.length}})</div>
                    </div>
                    <div class="results">
                        <div class="item btn" *ngFor="let result of selectedResultSet.Results.Units" [@fadeInOut]="'in'"
                             (click)="toggleSelection(result, 'Units')">
                            <checkbox [isSelected]="result.isSelected"></checkbox>
                            <div *ngFor="let parent of result.Lineage | reverse" class="label" [innerHTML]="parent.Name"></div>
                            <div class="label highlight">{{result.Name}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="friendly-message" *ngIf="inputControl.value && inputControl.value.length > 2 && !searchInProgress && searchResults?.length === 0">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="46.6" height="46.6" viewBox="0 0 46.6 46.6">
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path d="M23.2,42.7A19.4,19.4,0,1,0,3.9,23.2,19.5,19.5,0,0,0,23.2,42.7Zm.1,3.9A23.3,23.3,0,1,1,46.6,23.4,23.3,23.3,0,0,1,23.3,46.6Z" fill="#919193" />
                                <path d="M29.6,30.8c-.1-.8-.8-1.3-1.6-1.6a14.1,14.1,0,0,0-9.4-.1,3.3,3.3,0,0,0-1.4,1.1,1.3,1.3,0,0,0-.1,1.2c.1.2.7.3,1,.2a29.9,29.9,0,0,1,4.3-.6,59.4,59.4,0,0,1,6.1.6C29.2,31.8,29.6,31.7,29.6,30.8Zm-.1,4.1a48.5,48.5,0,0,0-5.1-.6,43.8,43.8,0,0,0-6,.4c-2,.3-3.5-.2-4.2-1.6a4.2,4.2,0,0,1,.6-5,8.7,8.7,0,0,1,4.9-2.7,15.2,15.2,0,0,1,9.8.9c2,1,3.6,2.4,3.5,4.9A3.5,3.5,0,0,1,29.5,34.9Z" fill="#919193" />
                                <path d="M19.4,18.5a2.9,2.9,0,1,1-2.8-2.9A2.9,2.9,0,0,1,19.4,18.5Z" fill="#919193" />
                                <path d="M33,18.4a2.9,2.9,0,1,1-2.9-2.9A3,3,0,0,1,33,18.4Z" fill="#919193" />
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="text">
                    Sorry, no results were found with that search.
                </div>
            </div>
            <div class="friendly-message" *ngIf="inputControl.value && inputControl.value.length < 3 && !searchInProgress">
                <div class="text">Search term must be at least 3 characters</div>
            </div>
            <div class="loading-bars" *ngIf="searchInProgress">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>